import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Grid, Flex } from 'system';
import { PageTemplate } from 'gatsby-theme-ww-prismic/src/templates/page';
import { MapCustomTypeSlicesToComponents } from 'gatsby-theme-ww-prismic/src/components/MapCustomTypeSlicesToComponents';
import { usePrismicPreviewPageData } from 'gatsby-theme-ww-prismic/src/hooks/usePrismicPreviewPageData';
import { slicesMap } from 'src/slices/PageBody';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
export var PageNotFoundTemplate = function PageNotFoundTemplate(_ref) {
  var data = _ref.data,
      location = _ref.location,
      props = _objectWithoutPropertiesLoose(_ref, ["data", "location"]);

  var injectSlices = function injectSlices(list) {
    if (list === void 0) {
      list = [];
    }

    return [{
      __typename: 'PageBody404',
      id: '404'
    }].concat(_toConsumableArray(list));
  };

  var mergedData = usePrismicPreviewPageData({
    data: data,
    location: location
  });
  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    listMiddleware: injectSlices
  }, props), React.createElement(Grid, {
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100vh"
  }, React.createElement(PageBodyHeader, null), React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(MapCustomTypeSlicesToComponents, Object.assign({
    data: mergedData,
    slicesMap: slicesMap,
    listMiddleware: injectSlices
  }, props))), React.createElement(PageBodyFooter, {
    withBackToTop: false
  })));
};
export default PageNotFoundTemplate;
export var query = "1604691817";